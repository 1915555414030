<template>
  <section id="survey-metrics">
    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="sidebarx sidebar-create-chart"
      spacer
      v-model="showCreateChart"
    >
      <div class="create-chart">
        <h4 class="create-chart__title">
          {{ lang.surveyCharts.create.title[languageSelected] }}
        </h4>
        <AddSurveyChart
          @onCancel="cancelCreate"
          @onCreate="createChart"
          ref="addChart"
        />
      </div>
    </vs-sidebar>

    <vs-popup
      :title="copyTitle"
      :active.sync="showCopyChart"
      class="popup-copy-chart"
    >
      <CopySurveyChart
        v-if="showCopyChart"
        :chart="chartToCopy"
        @onCancel="cancelCopy"
        @onCopy="copyCharts"
      />
    </vs-popup>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="metrics-header">
          <h4 class="mb-4">
            {{ lang.surveyIntegrations.metrics.title[languageSelected] }}
          </h4>
        </div>

        <Filters
          type="card"
          :filter-model="filterModel"
          @init="filterChange"
          @on-apply="onApplyFilters"
        />
      </div>
    </div>

    <vs-button
      color="primary"
      class="mt-4 mb-4 float-right"
      @click="openCreateChart"
    >
      {{ lang.surveyCharts.create.title[languageSelected] }}
    </vs-button>

    <section class="charts-wrapper mt-2">
      <vs-row class="vx-row" v-if="allCharts.length">
        <vs-col
          v-for="(chart, chartIdx) in allCharts"
          :key="`chart_${chartIdx}`"
          class="md:w-1/2 lg:w-1/2 xl:w-1/2 mb-8"
        >
          <div
            class="charts-wrapper__loading vx-card"
            v-show="loadingMap[chart.id]"
            :id="`div-with-loading-${chart.id}`"
            style="min-width: 50%; min-height: 450px; background: white"
          >
            {{ lang.surveyCharts.main.metric.loading[languageSelected] }}
          </div>

          <vx-card
            v-if="chart.chartInfo && chart.chartInfo.name"
            :title="chart.chartInfo.name"
            fixedHeight
          >
            <template slot="actions">
              <ChartDropdown
                :item="{
                  id: chart.chartInfo._id,
                  type: chart.chartInfo.type,
                  name: chart.chartInfo.name
                }"
                @edit="editChart"
                @copy="openCopyChart"
                @delete="removeChart"
              />
            </template>

            <template>
              <vs-row class="vx-row">
                <vs-col vs-w="4">
                  <vs-list>
                    <vs-list-item
                      :title="
                        lang.surveyCharts.main.metric.field[languageSelected]
                      "
                      :subtitle="chart.chartInfo.field"
                    />
                  </vs-list>
                </vs-col>
                <vs-col vs-w="4">
                  <vs-list>
                    <vs-list-item
                      :title="
                        lang.surveyCharts.main.metric.survey[languageSelected]
                      "
                      :subtitle="
                        chart.chartInfo.surveyService &&
                          chart.chartInfo.surveyService.name
                      "
                    />
                  </vs-list>
                </vs-col>

                <vs-col
                  vs-w="4"
                  v-if="
                    chart.chartInfo.npsFilter &&
                      chart.chartInfo.npsFilter != '0'
                  "
                >
                  <vs-list>
                    <vs-list-item
                      :title="
                        lang.surveyCharts.create.form.npsfilter.npsgroup[
                          languageSelected
                        ]
                      "
                      :subtitle="chart.chartInfo.npsGroup"
                    />
                  </vs-list>
                </vs-col>
                <vs-col
                  vs-w="4"
                  v-if="chart.chartInfo.field === 'nps' && chart.total"
                >
                  <vs-list>
                    <vs-list-item
                      :title="
                        lang.surveyCharts.main.metric.total[languageSelected]
                      "
                      :subtitle="`${chart.total}`"
                    />
                  </vs-list>
                </vs-col>
              </vs-row>

              <CustomChart :chart="chart" v-if="chart" />
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </section>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { Validator } from 'vee-validate'
import es from 'vee-validate/dist/locale/es'
import en from 'vee-validate/dist/locale/en'
import { NPS_VALUES, PALETTE } from '@/constants/constants'

export default {
  name: 'SurveyMetrics',
  data() {
    return {
      services: [],
      interval: {},
      surveyServices: [],
      filterModel: [],
      allCharts: [],
      showCreateChart: false,
      loadingMap: {},
      chartToCopy: null,
      showCopyChart: false,
      NPS_VALUES,
      PALETTE
    }
  },
  components: {
    Filters: () => import('../metrics/filters/Filters.vue'),
    ChartDropdown: () => import('./components/ChartDropdown.vue'),
    AddSurveyChart: () => import('./add-survey-chart/AddSurveyChart.vue'),
    CopySurveyChart: () => import('./copy-survey-chart/CopySurveyChart.vue'),
    CustomChart: () => import('./components/CustomChart.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('surveyMetrics', ['charts', 'chartsData', 'chartById']),
    copyTitle() {
      let title = this.lang.surveyCharts.copy.title[this.languageSelected]
      return this.chartToCopy ? `${title} "${this.chartToCopy.name}"` : title
    }
  },
  methods: {
    ...mapActions('surveyMetrics', [
      'getCharts',
      'getChartsData',
      'createNewChart',
      'copyChart',
      'deleteChart'
    ]),
    ...mapMutations('surveyMetrics', ['RESET_CHARTS']),
    parseRange(values) {
      const from = values[0]
      const to = values[1]

      return { from, to }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'botNoVersion':
          this.services = filter.value
          // this.$router.push({
          //   query: { services: this.services }
          // })
          break
        case 'survey':
          this.surveyServices = filter.value
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.interval = this.parseRange(filter.value)
          }
          break
        default:
          break
      }

      this.initCharts()
    },
    buildChart(chartId, chartType) {
      const chartData = this.chartsData[chartId]
      const chartInfo = this.charts.find(c => c._id === chartId)

      let chart = {}
      switch (chartType) {
        case 'pie':
          chart = this.pieFactory(chartData, chartInfo)
          break
        case 'bar':
          chart = this.stackedFactory(chartData, chartInfo)
          break
        case 'cluster':
          chart = this.clusterFactory(chartData, chartInfo)
          break
        default:
          break
      }

      return chart
    },
    clusterFactory(chartData, chartInfo) {
      if (chartInfo.npsFilter && chartInfo.npsFilter === '1') {
        chartInfo.npsGroup = this.lang.surveyCharts.create.form.npsfilter.detractores[
          this.languageSelected
        ]
      } else if (chartInfo.npsFilter && chartInfo.npsFilter === '2') {
        chartInfo.npsGroup = this.lang.surveyCharts.create.form.npsfilter.neutros[
          this.languageSelected
        ]
      } else if (chartInfo.npsFilter && chartInfo.npsFilter === '3') {
        chartInfo.npsGroup = this.lang.surveyCharts.create.form.npsfilter.promotores[
          this.languageSelected
        ]
      }
      return {
        chartInfo,
        chartData
      }
    },
    // normalizeData(obj) {
    //   // turns all the keys of an object to lower case
    //   let key
    //   const keys = Object.keys(obj)

    //   let n = keys.length
    //   let newObj = {}
    //   while (n--) {
    //     key = keys[n]
    //     newObj[key.toLowerCase().trim()] = obj[key]
    //   }

    //   return newObj
    // },
    findNPSGroupKey(chartGroups, npsGroup) {
      const group = chartGroups.find(
        g =>
          JSON.stringify(g.values.sort((a, b) => parseInt(a) - parseInt(b))) ===
          JSON.stringify(npsGroup)
      )
      return group && group.name
    },
    getNPSValue(data, chartId, field, total) {
      // const normalizedData = this.normalizeData(data)
      if (field === 'nps') {
        const chart = this.charts.find(c => c._id === chartId)
        const chartGroups = chart && chart.groups

        const promKey = this.findNPSGroupKey(chartGroups, NPS_VALUES.promoters)
        const detKey = this.findNPSGroupKey(chartGroups, NPS_VALUES.detractors)

        const promPct =
          total && promKey && data[promKey] ? data[promKey] / total : null

        const detPct =
          total && detKey && data[detKey] ? data[detKey] / total : null

        return promPct && detPct ? ((promPct - detPct) * 100).toFixed(2) : null
      }
      return null
    },
    pieFactory(chartData, chartInfo) {
      const series = Object.values(chartData)
      const labels = Object.keys(chartData)
      const total = series.reduce((acc, elem) => acc + elem, 0)
      const npsValue = this.getNPSValue(
        chartData,
        chartInfo._id,
        chartInfo.field,
        total
      )

      return {
        chartInfo,
        series,
        total,
        npsValue,
        chartOptions: {
          chart: {
            width: 500,
            type: 'pie'
          },
          labels: labels,
          responsive: [
            {
              breakpoint: 1300,
              options: {
                chart: {
                  width: 320
                },
                legend: {
                  position: 'bottom'
                },
                dataLabels: {
                  formatter: function(val) {
                    // hide <= 5% labels
                    return val > 5 ? `${val.toFixed(1)}%` : ''
                  }
                }
              }
            },
            {
              breakpoint: 1024,
              options: {
                legend: {
                  position: 'bottom'
                }
              }
            }
          ]
        }
      }
    },
    stackedFactory(chartData, chartInfo) {
      let categories = []
      let series = []

      chartData.forEach((d, i) => {
        categories.push(d.extravalue) // || `cat_${i}`

        Object.keys(d).forEach(dataField => {
          if (
            dataField !== 'extrafield' &&
            dataField !== 'extravalue' &&
            dataField !== '_total'
          ) {
            if (i === 0) {
              series.push({
                name: dataField,
                data: [d[dataField]]
              })
            } else {
              const entryIndex = series.findIndex(e => e.name === dataField)
              series[entryIndex].data.push(d[dataField])
            }
          }
        })
      })

      const total = series.length

      return {
        chartInfo,
        series,
        total,
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }
          ],
          dataLabels: {
            formatter: function(val) {
              // hide 0 labels
              return val > 0 ? val : ''
            }
          },
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            type: 'category',
            categories: categories,
            trim: true
          },
          yaxis: {
            tickAmount: total
          },
          legend: {
            position: 'right',
            offsetY: 40
          },
          fill: {
            opacity: 1
          },
          colors: this.getColors(series)
        }
      }
    },
    getColors(series) {
      const hasNoResponses = series.findIndex(s => s.name === 'NS/NC') !== -1
      const count = hasNoResponses ? series.length - 1 : series.length

      let colors = []
      for (let index = 0; index < count; index++) {
        colors.push(PALETTE[index % PALETTE.length])
      }
      if (hasNoResponses) {
        colors.push('#D0D0D0')
      }

      return colors
    },
    async getAllCharts() {
      await this.RESET_CHARTS()

      const filter = {
        service: this.services[0],
        surveyService: this.surveyServices[0]
      }
      await this.getCharts(filter)
    },
    async loadCharts() {
      try {
        const payload = {
          ...this.interval
        }

        this.allCharts = []
        this.charts.forEach(chart => {
          this.allCharts.push({
            id: chart._id
          })
        })

        this.charts.forEach((chart, chartIdx) => {
          const chartId = chart._id
          const chartType = chart.type

          this.$set(this.loadingMap, chartId, true)

          this.getChartsData({ chartId, chartType, payload })
            .then(() => {
              this.$set(
                this.allCharts,
                chartIdx,
                Object.assign(
                  this.allCharts[chartIdx],
                  this.buildChart(chartId, chartType)
                )
              )
            })
            .catch(error => {
              console.error(error)
              // this.$vs.notify({
              //   title: 'Oops!',
              //   text: this.lang.surveyCharts.main.messages.loadCharts.error
              //     .text[this.languageSelected],
              //   color: 'danger'
              // })
            })
            .finally(() => {
              this.$set(this.loadingMap, chartId, false)
            })
        })
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.main.messages.loadCharts.error.text[
            this.languageSelected
          ],
          color: 'danger'
        })
      }
    },
    async openCreateChart() {
      await this.$nextTick()
      this.showCreateChart = true
    },
    closeCreateChart() {
      this.showCreateChart = false
    },
    resetNewChartForm() {
      this.$refs.addChart.reset()
    },
    async createChart(newChart) {
      this.$vs.loading()
      try {
        await this.createNewChart(newChart)

        this.$vs.notify({
          title: '',
          text: this.lang.surveyCharts.create.messages.success.text[
            this.languageSelected
          ],
          color: 'success'
        })

        this.initCharts()
        this.resetNewChartForm()
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.text[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close()
      }
      this.showCreateChart = false
    },
    async editChart(chart) {
      const chartToEdit = this.chartById(chart.id)
      this.$refs.addChart.setChartToEdit(chartToEdit)
      await this.$nextTick()
      this.showCreateChart = true
    },
    async openCopyChart(chart) {
      this.chartToCopy = chart
      this.showCopyChart = true
    },
    async copyCharts(charts) {
      this.$vs.loading()
      try {
        await this.copyChart(charts)

        this.$vs.notify({
          title: '',
          text: this.lang.surveyCharts.copy.messages.success.text[
            this.languageSelected
          ],
          color: 'success'
        })

        this.initCharts()
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.copy.messages.error.text[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close()
      }
      this.showCopyChart = false
    },
    async removeChart(chart) {
      this.$vs.loading()

      try {
        await this.deleteChart(chart)

        this.$vs.notify({
          title: '',
          text: this.lang.surveyCharts.delete.messages.success.text[
            this.languageSelected
          ],
          color: 'success'
        })

        this.initCharts()
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.delete.messages.error.text[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    cancelCreate() {
      this.showCreateChart = false
    },
    cancelCopy() {
      this.showCopyChart = false
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setUTCHours(0, 0, 0, 0)),
        to: new Date(to.setUTCHours(23, 59, 59, 999))
      }
    },
    async initCharts() {
      this.$vs.loading()
      try {
        await this.getAllCharts()
        await this.loadCharts()
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.main.messages.loadCharts.error.text[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  watch: {
    languageSelected() {
      Validator.localize(
        this.languageSelected,
        this.languageSelected === 'es' ? es : en
      )
    }
  },
  async mounted() {
    Validator.localize(
      this.languageSelected,
      this.languageSelected === 'es' ? es : en
    )
    this.interval = this.weekInterval()

    this.filterModel = [
      {
        name: 'botsNoVersion',
        component: 'BotNoVersionFilter',
        width: 4,
        props: {
          multipleSelect: false,
          allowEmpty: true
        }
      },
      {
        name: 'surveys',
        component: 'SurveyFilter',
        width: 4,
        props: {
          multipleSelect: false
        }
      },
      {
        name: 'dates',
        component: 'DateRangeFilter',
        width: 4,
        props: {
          value: this.interval
        }
      }
    ]

    this.initCharts()
  },
  beforeDestroy() {
    document.getElementsByClassName('sidebar-create-chart')[0].style.display =
      'none'
  }
}
</script>

<style lang="scss">
#survey-metrics {
  .charts-wrapper {
    .vx-row {
      width: auto !important;
    }

    .apexcharts-legend {
      justify-content: center;
      max-width: 160px;
    }

    &__loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
    }
  }
}

.sidebar-create-chart {
  .vs-sidebar {
    z-index: 99999;
    max-width: 500px;

    .vs-sidebar--items {
      overflow-y: auto;
      overflow-x: visible;
      display: flex;
      flex-direction: column;

      .create-chart {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 20px;

        &__title {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.popup-copy-chart {
  .vs-popup--content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: visible;
  }
}
</style>
